import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADEPT_USER_SURVEY_MANAGE } from 'Consts/routes';

import { getFormattedDate } from 'Utils/date';
import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';

export default class AdeptUserSurveysList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
        userSurveys: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedState: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',    
        timestamp: undefined,
        ...this.props.predefinedQuery, //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    componentDidMount = () => {
        this.loadData();
    }

    render() {
        const { location, history, userSurveys } = this.props;

        return (
            <StyledComponent
                className="adept-user-surveys-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={userSurveys}
                    queryConfig={this.getQueryConfig()}
                    styleProps={{
                        styleVersion: 2,
                    }}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={element.createdAt && getFormattedDate(element.createdAt) || 'Brak'}
                            controls={[{
                                type: 'button',
                                label: 'Szczegóły',
                                visible: true,
                                to: withVariables(ADEPT_USER_SURVEY_MANAGE.path, { id: element.id }),
                                style: 'gradient',
                            }]}
                        />
                    )}
                />
            </StyledComponent>
        );
    }
}