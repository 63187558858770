import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .basic-data-container {
        .basic-data-headline {
            font-size: 1.5em;
            color: ${variables.dpc_fontDark};
            padding-bottom: 1em;
        }

        .basic-data {
            display: flex;
            justify-content: space-between;
            padding-bottom: 2em;
            
            .admin-panel-text-box {
                min-width: 25vw;
            }
        }
    }

    .no-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 6em 0;

        .headline {
            font-size: 2em;
            color: ${variables.dpc_fontDark};
            text-align: center;
        }
        .control {
            margin-top: 1em;
        }
    }

    .card-container {
        width: 100%;

        .card-headline {
            color: ${variables.dpc_fontDark};
            font-size: 2em;
            font-weight: 600;
            margin-bottom: 1em;
        }

        .categories {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            .category {
                width: 100%;
                margin-bottom: 3.5em;

                .category-headline {
                    color: ${variables.dpc_fontDark};
                    font-size: 1.6em;
                    padding-bottom: .75em;
                    border-bottom: 1px solid #CCCCCC;
                }
                .category-answers {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;

                    .answer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 1.2em;
                        width: 100%;

                        padding: .75em .25em;
                        border-bottom: 1px dashed #666666;

                        .answer-question {
                            color: ${variables.dpc_fontDark};
                        }

                        .answer-value {
                            color: ${variables.dpc_fontDark};
                            text-align: right;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            flex-direction: column;

                            .answer-value-bool {
                                font-weight: 600;

                                &.true {
                                    color: red;
                                }
                                &.false {
                                    color: ${variables.dpc_fontDark};
                                }
                            }

                            .answer-value-text {
                                margin-top: .5em;
                                font-size: .9em;
                                font-weight: 300;
                                color: ${variables.dpc_fontDark};

                                span:nth-of-type(2) {
                                    font-weight: bold;
                                }
                            }
                        }

                        &:hover {
                            background-color: ${variables.dpc_backgroundLightRed};
                        }
                    }
                }
            }
        }
    }
    
    @media (max-width: ${variables.tabletS}) {
        .basic-data-container {
            .basic-data {
                flex-wrap: wrap;
            }
        }  
    }
`;