import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import MeasurementsProgress from 'Components/adept/measurements/Progress';
import MeasurementsList from 'Components/adept/measurements/List';
import MeasurementsChart from 'Components/adept/measurements/Chart';

export default class AdeptMeasurementsDashboard extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    render() {
        const { location, history, predefinedQuery }  = this.props;

        return (
            <StyledComponent
                className="adept-measurements-dashboard"
                styles={require('./styles')}
            >
                <section className="section section-chart">
                    <h2 className="section-headline">
                        Wykresy
                    </h2>
                    <MeasurementsChart
                        location={location} 
                        history={history}
                        predefinedState={predefinedQuery}
                    />
                </section>
                <section className="section section-progress">
                    <h2 className="section-headline">
                        Twój progres
                    </h2>
                    <MeasurementsProgress 
                        location={location} 
                        history={history}
                        predefinedState={predefinedQuery}
                    />
                </section>
                <section className="section section-list">
                    <h2 className="section-headline">
                        Historia i edycja pomiarów
                    </h2>
                    <MeasurementsList 
                        location={location} 
                        history={history}
                        predefinedState={predefinedQuery}
                    />
                </section>
            </StyledComponent>
        );
    }
}
