import { connect } from 'react-redux';
import Component from './component';

import { progress } from 'Redux/modules/adept/measurements/actions';
import { single as config } from 'Redux/modules/adept/measurementsConfig/actions';
export default connect(
    state => ({
        config: state.adeptMeasurementsConfig.mapById,
        progress: state.adeptMeasurements.progress,
    }),
    dispatch => ({
        actions: {
            config: dispatch(config),
            progress: dispatch(progress),
        },
    })
)(Component);