import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { 
    ADEPT_MEASUREMENTS_CREATE, 
    ADEPT_TRAINING_TARGETS_CREATE, 
    ADEPT_MEDICAL_CARD_CREATE,
    ADEPT_MEASUREMENTS_CONFIG_MANAGE,
} from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import MedicalDataPanel from 'Components/adept/medical/Panel';
import MeasurementsDashboard from 'Components/adept/measurements/Dashboard';
import TrainingTargetsList from 'Components/adept/trainingTargets/List';
import TestsList from 'Components/adept/adeptTests/List';
import UserSurveysList from 'Components/adept/userSurveys/List';
import UserHomeworksList from 'Components/adept/userHomeworks/List';
import DiagnosticReportsList from 'Components/adept/userOfferDiagnosticReports/List';

export default class PageAdeptAdeptData extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { location, history } = this.props;
        const queryObject = parseQueryToObject(location.search);

        return (
            <StyledComponent
                className="page-adept-adept-data"
                styles={require('./styles')}
            >
                <Head title="Kartoteka" />
                <TabNavigation
                    location={location}
                    history={history}
                    headline="Kartoteka"
                    controls={[{
                        visible: Boolean(!queryObject.tab || queryObject.tab === 'measurementsData'),
                        key: 'createMeasurement',
                        label: 'Dodaj nowy pomiar',
                        onClick: () => history.push(
                            withVariables(
                                ADEPT_MEASUREMENTS_CREATE.path,
                            )
                        ),
                        style: 'gradient',
                        layout: 'wide',
                    }, {
                        visible: Boolean(!queryObject.tab || queryObject.tab === 'measurementsData'),
                        key: 'createMeasurementConfig',
                        label: 'Ustawienia pomiarów',
                        onClick: () => history.push(
                            withVariables(
                                ADEPT_MEASUREMENTS_CONFIG_MANAGE.path,
                            )
                        ),
                        style: 'gradient',
                        layout: 'wide',
                    }, {
                        visible: Boolean(queryObject.tab === 'trainingTargets'),
                        key: 'createTrainignTargets',
                        label: 'Dodaj nowy cel treningowy',
                        onClick: () => history.push(
                            withVariables(
                                ADEPT_TRAINING_TARGETS_CREATE.path,
                            )
                        ),
                        style: 'gradient',
                        layout: 'wide',
                    }, {
                        visible: Boolean(queryObject.tab === 'medicalData'),
                        key: 'createMedicalCard',
                        label: 'Dodaj nowy wywiad',
                        onClick: () => history.push(
                            withVariables(
                                ADEPT_MEDICAL_CARD_CREATE.path,
                            )
                        ),
                        style: 'gradient',
                        layout: 'wide',
                    }]}
                    tabs={[{
                        key: 'measurementsData',
                        label: 'Pomiary',
                        children: (
                            <div className="measurements-content">
                                <MeasurementsDashboard 
                                    location={location} 
                                    history={history}
                                />
                            </div>
                        ),
                    },
                    {
                        key: 'userHomeworks',
                        label: 'Prace domowe',
                        visible: true,
                        children: (
                            <UserHomeworksList
                                location={location}
                                history={history}
                            />
                        ),
                    },
                    {
                        key: 'diagnosticReports',
                        label: 'Raporty diagnostyczne',
                        visible: true,
                        children: (
                            <DiagnosticReportsList
                                location={location}
                                history={history}
                            />
                        ),
                    },
                    {
                        key: 'medicalData',
                        label: 'Wywiad medyczny',
                        children: (
                            <MedicalDataPanel />
                        ),
                    },
                    {
                        key: 'userSurveys',
                        label: 'Ankiety',
                        visible: true,
                        children: (
                            <UserSurveysList
                                location={location}
                                history={history}
                            />
                        ),
                    },
                    {
                        key: 'trainingTargets',
                        label: 'Aktywne cele treningowe',
                        children: (
                            <TrainingTargetsList 
                                location={location} 
                                history={history}
                                predefinedQuery={{
                                    archivalTargets: false,
                                }}
                            />
                        ),
                    },
                    {
                        key: 'trainingTargetsArchive',
                        label: 'Archiwalne cele treningowe',
                        children: (
                            <TrainingTargetsList 
                                location={location} 
                                history={history}
                                predefinedQuery={{
                                    archivalTargets: true,
                                }}
                            />
                        ),
                    },
                    {
                        key: 'tests',
                        label: 'Testy',
                        visible: false,
                        children: (
                            <TestsList 
                                location={location} 
                                history={history}
                            />
                        ),
                    }]}
                />
            </StyledComponent>
        );
    }
}
