import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .admin-paginated-list {
            .list-body {
                .admin-paginated-list-element-v2 {
                    .wrapper {
                        .block-right {
                            .details-block {
                                .additionals-block {
                                    .additional {
                                        width: 12em;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media (max-width: ${variables.tabletL}) {
            .admin-paginated-list {
                .list-body {
                    .admin-paginated-list-element-v2 {
                        .wrapper {
                            .block-right {
                                .details-block {
                                    .additionals-block {
                                        .additional {
                                            margin-bottom: 2em;    
                                            width: 100%;
                                            
                                            .additional-name {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;
