import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import StyledComponent from 'Components/core/StyledComponent';
import MeasurementsBox from 'Components/layout/panel/MeasurementsBox';
import Spinner from 'Components/layout/Spinner';

export default class AdeptMeasurementsProgress extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            progress: PropTypes.func.isRequired,
            config: PropTypes.func.isRequired,
        }).isRequired,
        progress: PropTypes.object.isRequired,
        config: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
        predefinedState: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    types = [{
        icon: require('Theme/images/icons/weight.svg'),
        key: 'weight',
    }, {
        icon: require('Theme/images/icons/belt.svg'),
        key: 'belt',
    }, {
        icon: require('Theme/images/icons/bicepsLeft.svg'),
        key: 'bicepsLeft',
    }, {
        icon: require('Theme/images/icons/bicepsRight.svg'),
        key: 'bicepsRight',
    }, {
        icon: require('Theme/images/icons/calfLeft.svg'),
        key: 'calfLeft',
    }, {
        icon: require('Theme/images/icons/calfRight.svg'),
        key: 'calfRight',
    }, {
        icon: require('Theme/images/icons/chest.svg'),
        key: 'chest',
    }, {
        icon: require('Theme/images/icons/hips.svg'),
        key: 'hips',
    }, {
        icon: require('Theme/images/icons/thighLeft.svg'),
        key: 'thighLeft',
    }, {
        icon: require('Theme/images/icons/thighRight.svg'),
        key: 'thighRight',
    }, {
        icon: require('Theme/images/icons/waist.svg'),
        key: 'waist',
    }]

    componentDidMount = () => {
        this.loadData();
    }

    loadData = () => {
        const { actions, predefinedState } = this.props;

        actions.config({ id: predefinedState.userId });
        actions.progress({ userId: predefinedState.userId });
    }

    render() {
        const { progress, config } = this.props;
        if(!config?.data?.fieldsConfig) {
            return (
                <StyledComponent
                    className="adept-measurements-progress"
                    styles={require('./styles')}
                >
                    <Spinner />
                </StyledComponent>
            );
        }

        const fieldsConfig = config.data.fieldsConfig;
        
        let results = [];        
        Object.keys(fieldsConfig)
            .map(fieldsConfigKey => {
                const config = fieldsConfig[fieldsConfigKey];
                const type = this.types.find(type => type.key === fieldsConfigKey);

                let result = {
                    key: fieldsConfigKey,
                    icon: type?.icon || require('Theme/images/icons/star.svg'),
                    name: config?.name || fieldsConfigKey,
                    unit: config?.unit || null,
                    first: progress.elements?.first && progress.elements?.first[fieldsConfigKey] || null,
                    last: progress.elements?.last && progress.elements?.last[fieldsConfigKey] || null,
                };
    
                result.difference = result.first !== null && result.last !== null
                    ? result.last - result.first
                    : null;
                result.differenceSign = result.difference > 0
                    ? '+'
                    : '';
    
                if(result.last) {
                    results.push(result);
                }
            });

        return (
            <StyledComponent
                className="adept-measurements-progress"
                styles={require('./styles')}
            >
                {results.map(result => (
                    <MeasurementsBox
                        key={result.key}
                        headline={result.name}
                        icon={result.icon && (
                            <ReactSVG className="box-icon" 
                                src={result.icon}
                                beforeInjection={svg => {
                                    svg.setAttribute('width', '100');
                                    svg.setAttribute('height', '100');
                                }}
                            />
                        )}
                    >
                        <div className="box-body">
                            <span className="last">
                                {result.last.toFixed(2)}
                            </span>
                            <span className={`difference ${result.differenceSign && result.differenceSign === '+' ? 'plus' : 'minus'}`}>
                                ({result.differenceSign} {result.difference.toFixed(2)})
                            </span>
                            <span className="unit">
                                {result.unit}
                            </span>
                        </div>
                    </MeasurementsBox> 
                ))}
            </StyledComponent>
        );
    }
}
