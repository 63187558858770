import { connect } from 'react-redux';
import Component from './component';

import {
    list,
} from 'Redux/modules/adept/measurements/actions';

import {
    getProfile,
} from 'Redux/modules/user/actions';

export default connect(
    state => ({
        measurements: state.adeptMeasurements.list,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            getProfile: dispatch(getProfile),
        },
    })
)(Component);
