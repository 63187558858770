import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    .section {
        margin-bottom: 1em;
        padding-bottom: 1em;
        border-bottom: 1px solid ${variables.dpc_borderLight};

        .section-headline {
            color: ${variables.dpc_fontDark};
            font-weight: 600;
            font-size: 1.5em;
            margin: .5em 0;
        }
    }
`;
