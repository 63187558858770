import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    min-height: 10em;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
    .panel-measurements-box {
        width: 32%;
        margin-bottom: 1em;

        .measurements-box-content {
            .measurements-box-body {
                align-items: flex-start;
                            
                .measurements-box-headline {
                    font-size: 1.8em;
                }

                .measurements-box-children {                    
                    .box-body {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .last {
                            color: ${variables.dpc_fontDark};
                            font-weight: 600;
                            font-size: 1.8em;
                        }
                        .difference {
                            color: #EEEEEE;
                            font-weight: 600;
                            font-size: 1.3em;
                            margin-left: .3em;

                            &.minus {
                                color: #0a9676;
                            }
                            &.plus {
                                color: #0081dc;
                            }
                        }
                        .unit {
                            color: ${variables.dpc_fontDark};
                            font-weight: 300;
                            font-size: 1em;
                            margin-left: .5em;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: ${variables.desktopXS}) {
        .panel-measurements-box {
            width: 48%;
        }
    }
        
    @media (max-width: ${variables.tabletS}) {
        .panel-measurements-box {
            width: 100%;
        }
    }
`;
